<template>
  <a-modal
    v-if="visible"
    :visible="visible"
    destroy-on-close
    width="800px"
    :mask-closable="false"
    @cancel="emit('update:visible', false)"
    :title="model.title">
    <!-- <a-spin :spinning="loading"> -->
      <a-form :model="form" class="myform" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" ref="formRef">
          <a-form-item label="Name">
            <a-input :disabled="model.readOnly" v-model:value="name" ></a-input>
          </a-form-item>
          <a-form-item label="Link">
            <a-input :disabled="model.readOnly" v-model:value="href"></a-input>
          </a-form-item>
          <a-form-item label="Icon">
            <a-input :disabled="model.readOnly" v-model:value="icon"></a-input>
          </a-form-item>
          <a-form-item label="Parent">
            <a-tree-select
              show-search
              allow-clear
              v-model:value="parent_id"
              :disabled="model.readOnly"
              :tree-data="items"
              :replace-fields="{
                children: 'children',
                title: 'name',
                key: 'id',
                value: 'id',
              }"
              tree-node-filter-prop="title"
            ></a-tree-select>
          </a-form-item>
          <a-form-item label="Sequence">
            <a-input-number v-model:value="sequence" :disabled="model.readOnly"></a-input-number>
          </a-form-item>
          <a-form-item label="Note">
            <quill-editor
              class="mb-2"
              v-model:content="note"
              content-type="html"
              toolbar="full"
              :read-only="model.readOnly"
            />
          </a-form-item>
      </a-form>
    <!-- </a-spin> -->
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <template #footer>
      <a-button key="submit" :loading="isSubmit" @click="handleOk" :hidden="model.readOnly" type="primary">
        Simpan
      </a-button>
      <a-button key="cancel" @click="emit('update:visible', false)">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'

export default defineComponent({
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        name: null,
        record: {},
      }),
    },
    items: { type: [Array], default: () => [] },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const formRef = ref()
    const form = reactive(Object.assign({}, props.model.record))
    const errorMessage = ref(null)
    const isSubmit = ref(false)

    const handleOk = async () => {
      isSubmit.value = true
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      const form_data = serialize(
        {
          ..._.omit(form, [
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
          ]),
        },
        { indices: true },
      )

      const url = `/api/menus${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
          record.value = {}
        })
        .catch(async error => {
          // message.error('Gagal menyimpan')
          // console.error(error.response.data)
          if (error.response && error.response.data) {
            message.error(error.response.data[0].message)
          }
        })
        .finally(() => {
          isSubmit.value = false
        })
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    return {
      formRef,
      form,
      ...toRefs(form),
      emit,
      handleOk,
      handleCancel,
      errorMessage,
      isSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
