<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Tambah Menu" type="primary" @click="showModal">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        :loading="isFetching">
        <!-- <template #note="{record}">
          <span v-html="record.note"></span>
        </template> -->
        <template #action="{record}">
          <div class="d-flex align-items-center ">
          <a-tooltip title="Lihat data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-primary"
              @click="view(record)">
              <small>
                <i class="fe fe-eye" />
              </small>
            </a>
          </a-tooltip>
          <a-tooltip title="Ubah data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-primary"
              @click="edit(record)">
              <small>
                <i class="fe fe-edit" />
              </small>
            </a>
          </a-tooltip>
          <a-tooltip title="Hapus data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-danger"
              @click="hapus(record)">
              <small>
                <i class="fe fe-trash" />
              </small>
            </a>
          </a-tooltip>
        </div>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      v-model:items="items"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, createVNode} from 'vue'
import { useMenu } from './api'
import apiClient from '@/services/axios'
import MModal from './modal'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'

const columns = [
    {
        title: 'Nama',
        dataIndex: 'name',
    },
    {
        title: 'Link',
        dataIndex: 'href',
    },
    {
        title: 'Icon',
        dataIndex: 'icon',
    },
    // {
    //     title: 'Note',
    //     dataIndex: 'note',
    //     slots: { customRender: 'note' },
    // },
    {
        title: 'No Urut',
        dataIndex: 'sequence',
    },
    {
        title: 'Aksi',
        slots: { customRender: 'action' },
    },
]

const initial = () => ({
    id: null,
    name: null,
    href: null,
    icon: null,
    parent_id: null,
    sequence: null,
    note: null,
    record: {},
})

export default defineComponent({
  components: { MModal },
  emits: ['handleOk'],
  setup() {
    const { items } = useMenu()
    const state = reactive({
      data: [],
      isFetching: false,
      params: [],
    })

    const visible = ref(false)
    const formState = reactive({ ...initial() })
    const model = reactive({ readOnly: false })

    const showModal = () => {
      model.record = reactive({ ...initial() })
      model.readOnly = false
      visible.value = true
      model.title = "Tambah Menu"
      model.note = null
    }

    const handleOk = form => {
      fetchData()
      visible.value = false
    }

    const params = ref({})
    const errorMessage = ref()

    const fetchData = () => {
      state.isFetching = true

      apiClient
          .get('/api/menus', { params })
          .then(response => {
            state.data = response.data
          })
          .finally(() => {
              state.isFetching = false
          })
    }

    onMounted(() => {
      fetchData()
    })

    const view = record => {
      visible.value = true
      model.readOnly = true
      model.record = record
      model.title = "Lihat Menu"
    }

    const edit = record => {
      visible.value = true
      model.readOnly = false
      model.record = record
      model.title = "Ubah Menu"
    }

    const hapus = record => {
      Modal.confirm({
        title: 'Konfirmasi',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Apakah anda yakin hapus data ini?',

        onOk() {
          apiClient
            .delete('/api/menus/' + record.id)
            .then(response => {
              message.success('Berhasil dihapus')
              fetchData()
            })
            .catch(error => {
              console.error(error.response.data)
              if (error.response && error.response.data) {
                message.error(error.response.data[0].message)
              }
            })
            .finally(() => {})
        },

        onCancel() {
          console.log('Cancel')
        },
      })
    }

    return {
      visible,
      showModal,
      handleOk,
      columns,
      model,
      view,
      edit,
      hapus,
      ...toRefs(formState),
      ...toRefs(state),
      errorMessage,
      items,
    }
  },
})
</script>

<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
