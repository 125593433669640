import apiClient from '@/services/axios'
import { reactive, onMounted, toRefs } from 'vue'

export function useMenu() {
    const menus = reactive({ items: [], loading: false })

    const refresh = (params = {}) => {
        menus.loading = true

        apiClient
            .get('/api/menus', { params })
            .then(response => {
                menus.items = response.data
            })
            .finally(() => {
                menus.loading = false
            })
    }

    onMounted(() => {
        refresh()
    })

    return {...toRefs(menus), refresh }
}